
import { fromatCDNPath } from "@/assets/js/filter";
import { mapGetters } from "vuex";
export default {
  props: {
    model1List: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      translateIndex: 0,
      translateX: 0,
      roomInfo: {},
      timer: null,
      isScroll: false,
    };
  },
  watch: {
    getBattleReport: {
      handler(val, oldVal) {
        if (val && !this.isScroll) {
          this.isScroll = true;
          this.timer = setTimeout(() => {
            this.isScroll = false;
          }, 305);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("battle", ["getBattleReport"]),
    ...mapGetters(["getIsLogin"]),
  },
  mounted() {
    // 获取比武战报
    this.getBattleResult();
  },
  destroyed() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    scrollToFnMain(item, status) {
      if (status) {
        if (this.translateIndex < item.length - 5) {
          this.translateIndex += 5;
          this.translateX = -(this.translateIndex * 296);
        }
      } else {
        if (this.translateIndex > 0) {
          this.translateIndex -= 5;
          this.translateX = -(this.translateIndex * 296);
        }
      }
    },
    formatCdnPath(_value) {
      return fromatCDNPath(_value);
    },
    goBattle() {
      this.$router.push({
        path: "/navBattle",
      });
    },
    // 观看房间
    handleJoin(item) {
      let _sendData = {
        room_id: item.room_id,
      };
      let callFunc = function (_data) {
        $nuxt.$router.push({
          path: "/navBattle/detail",
          query: { roomID: _data.room_id },
        });
      };
      this.$store.dispatch("battle/GetPkRoomData", { _sendData, callFunc });
    },
    handleJoinCallback(item, el, ex) {
      if (item.room_state != 1) {
        return false;
      }
      if (el.user_id) {
        return false;
      } else {
        if (!this.getIsLogin) {
          this.$store.commit("SET_VISIBLE_LOGIN", true);
        } else {
          this.roomInfo.item = item;
          this.roomInfo.el = el;
          this.roomInfo.ex = ex;
          let _SendData = {
            room_id: item.room_id,
            position: ex + 1,
          };
          if (item.play_type == 4) {
            _SendData.team = _SendData.position <= 2 ? 1 : 2;
          }
          this.$JoinPkRoom(_SendData)
            .then((res) => {
              // console.log("加入房间", res);
              if (res.code == 200) {
                let _sendData = {
                  room_id: item.room_id,
                };
                let callFunc = function (_data) {
                  $nuxt.$router.push({
                    path: "/navBattle/detail",
                    query: { roomID: _data.room_id },
                  });
                };

                this.$store.dispatch("getUserMoney");

                this.$store.dispatch("battle/GetPkRoomData", {
                  _sendData,
                  callFunc,
                });
              }
            })
            .catch((err) => {
              let { code, msg } = err;
              if (code == 200001) {
                this.$store.dispatch("showMessagePopupFn", {
                  type: 0,
                  text: msg,
                });

                let _sendData = {
                  room_id: item.room_id,
                };
                let callFunc = function (_data) {
                  $nuxt.$router.push({
                    path: "/navBattle/detail",
                    query: { roomID: _data.room_id },
                  });
                };
                this.$store.dispatch("battle/GetPkRoomData", {
                  _sendData,
                  callFunc,
                });
              }
            });
        }
      }
    },
    // 移动
    scrollToFn(item, index, status) {
      this.$store.commit("battle/SET_ROOM_TRANS", { item, index, status });
    },
    // 获取比武战报
    getBattleResult() {
      this.$getPkRealTimeReuslt({}).then((res) => {
        if (
          res.data.results &&
          Array.isArray(res.data.results) &&
          res.data.results.length
        ) {
          res.data.results.reverse().forEach((item) => {
            this.$store.commit("battle/SET_BATTLE_REPORT", item);
          });
        }
      });
    },
  },
};
